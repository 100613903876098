export default class VacationRecordsTableItem {
    vacationRecordsId = 0;
    vacationRecordsStatus = 0;
    userName = "";
    userDdOpenId = 0;
    calType = "";
    recordNumPerHour = "";
    startTime = "";
    endTime = "";
    gmtCreate = "";
    gmtModified = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
    batchId = 0;
    batchStatus = 0;
    batchName = "";
}
